import { SvgIcon as MuiSvgIcon, styled } from "@mui/material";

const SvgIcon = styled(MuiSvgIcon, {
  name: "HydraTextIcon",
  shouldForwardProp: (prop) => prop !== "fill",
})(() => ({}));

const HydraTextIcon = (props: any) => {
  return (
    <SvgIcon
      {...props}
      className="h-inherit w-auto"
      viewBox="0 0 1900 499.28"
      strokeMiterlimit="10"
      focusable="false"
    >
      <path
        d="M234.714 83.3439C234.714 44.9869 265.808 13.8924 304.165 13.8924C342.522 13.8924 373.617 44.9869 373.617 83.3439C373.617 121.701 342.522 152.795 304.165 152.795C265.808 152.795 234.714 121.701 234.714 83.3439Z"
        fill="#6a488e"
        fillRule="nonzero"
        opacity="1"
        stroke="#6a488e"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="3.24"
      />
      <path
        d="M234.714 318.51C234.714 280.153 265.808 249.059 304.165 249.059C342.522 249.059 373.617 280.153 373.617 318.51C373.617 356.867 342.522 387.962 304.165 387.962C265.808 387.962 234.714 356.867 234.714 318.51Z"
        fill="#6a488e"
        fillRule="nonzero"
        opacity="1"
        stroke="#6a488e"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="3.24"
      />
      <path
        d="M0 83.7962C0 45.4392 31.0945 14.3447 69.4515 14.3447C107.809 14.3447 138.903 45.4392 138.903 83.7962C138.903 122.153 107.809 153.248 69.4515 153.248C31.0945 153.248 0 122.153 0 83.7962Z"
        fill="#6a488e"
        fillRule="nonzero"
        opacity="1"
        stroke="#6a488e"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="3.24"
      />

      <path
        d="M477.439 390.675L477.439 10.856L539.848 10.856L539.848 390.675L477.439 390.675ZM520.273 222.505L520.273 165.523L732.439 165.523L732.439 222.505L520.273 222.505ZM718.872 390.675L718.872 10.856L781.281 10.856L781.281 390.675L718.872 390.675Z"
        fill="#6a488e"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M939.695 393.389L828.444 130.248L894.083 130.248L970.06 323.42L951.066 323.42L1031.37 130.248L1097.59 130.248L976.585 393.389L939.695 393.389ZM862.103 499.213L942.926 332.659L976.585 393.453L928.325 499.278L862.103 499.278L862.103 499.213Z"
        fill="#6a488e"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M1232.68 396.102C1209.17 396.102 1187.91 390.158 1168.92 378.206C1149.92 366.254 1134.93 350.102 1123.89 329.622C1112.84 309.207 1107.35 286.336 1107.35 261.011C1107.35 235.685 1112.84 212.298 1123.89 191.818C1134.93 171.402 1149.92 155.121 1168.92 142.975C1187.91 130.894 1209.17 124.821 1232.68 124.821C1252.58 124.821 1270.22 129.085 1285.6 137.548C1300.97 146.076 1313.18 157.706 1322.23 172.565C1331.27 187.424 1335.8 204.222 1335.8 223.022L1335.8 297.901C1335.8 316.701 1331.34 333.563 1322.49 348.358C1313.64 363.217 1301.42 374.847 1285.85 383.375C1270.28 391.838 1252.58 396.102 1232.68 396.102ZM1242.44 339.701C1257.3 339.701 1270.22 336.341 1281.2 329.687C1292.25 322.968 1300.84 313.665 1306.98 301.712C1313.12 289.76 1316.22 276.064 1316.22 260.494C1316.22 244.924 1313.12 231.163 1306.98 219.275C1300.84 207.323 1292.25 198.02 1281.2 191.365C1270.16 184.646 1257.23 181.351 1242.44 181.351C1227.64 181.351 1215.24 184.711 1204.19 191.365C1193.14 198.084 1184.49 207.388 1178.16 219.275C1171.82 231.227 1168.66 244.988 1168.66 260.494C1168.66 275.999 1171.82 289.76 1178.16 301.712C1184.49 313.665 1193.14 322.968 1204.19 329.687C1215.24 336.406 1227.97 339.701 1242.44 339.701ZM1372.1 390.675L1312.41 390.675L1312.41 320.707L1322.74 257.199L1312.41 194.273L1312.41 0.00213249L1372.1 0.00213249L1372.1 390.675Z"
        fill="#6a488e"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M1438.32 390.675L1438.32 130.248L1498.02 130.248L1498.02 390.675L1438.32 390.675ZM1498.02 245.828L1477.41 235.491C1477.41 202.607 1484.71 175.924 1499.38 155.444C1514.04 135.029 1536.2 124.756 1565.86 124.756C1578.91 124.756 1590.6 127.147 1601.13 131.799C1611.6 136.515 1621.35 144.074 1630.46 154.604L1591.38 194.725C1586.66 189.686 1581.43 186.068 1575.61 183.871C1569.8 181.674 1563.14 180.641 1555.52 180.641C1538.85 180.641 1525.16 185.874 1514.3 196.405C1503.45 206.871 1498.02 223.345 1498.02 245.764L1498.02 245.828Z"
        fill="#6a488e"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M1760.58 396.102C1737.06 396.102 1715.81 390.158 1696.81 378.206C1677.82 366.254 1662.83 350.102 1651.78 329.622C1640.74 309.207 1635.24 286.336 1635.24 261.011C1635.24 235.685 1640.74 212.298 1651.78 191.818C1662.83 171.402 1677.82 155.121 1696.81 142.975C1715.81 130.894 1737.06 124.821 1760.58 124.821C1780.48 124.821 1798.12 129.085 1813.49 137.548C1828.87 146.076 1841.08 157.706 1850.12 172.565C1859.17 187.424 1863.69 204.222 1863.69 223.022L1863.69 297.901C1863.69 316.701 1859.23 333.563 1850.38 348.358C1841.53 363.217 1829.32 374.847 1813.75 383.375C1798.18 391.838 1780.48 396.102 1760.58 396.102ZM1770.34 339.701C1792.37 339.701 1810.2 332.271 1823.76 317.476C1837.33 302.682 1844.12 283.687 1844.12 260.494C1844.12 244.924 1841.01 231.163 1834.88 219.275C1828.74 207.323 1820.15 198.02 1809.1 191.365C1798.05 184.646 1785.13 181.351 1770.34 181.351C1755.54 181.351 1743.14 184.711 1732.09 191.365C1721.04 198.084 1712.38 207.388 1706.05 219.275C1699.72 231.227 1696.56 244.988 1696.56 260.494C1696.56 275.999 1699.72 289.76 1706.05 301.712C1712.38 313.665 1721.04 322.968 1732.09 329.687C1743.14 336.406 1755.86 339.701 1770.34 339.701ZM1840.3 390.675L1840.3 320.707L1850.64 257.199L1840.3 194.273L1840.3 130.248L1900 130.248L1900 390.675L1840.3 390.675Z"
        fill="#6a488e"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
    </SvgIcon>
  );
};

export default HydraTextIcon;

export const HydraTextIconWhite = (props: any) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 1900 499.28"
      strokeMiterlimit="10"
      focusable="false"
    >
      <path
        d="M234.714 83.3439C234.714 44.9869 265.808 13.8924 304.165 13.8924C342.522 13.8924 373.617 44.9869 373.617 83.3439C373.617 121.701 342.522 152.795 304.165 152.795C265.808 152.795 234.714 121.701 234.714 83.3439Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="#FDF9ED"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="3.24"
      />
      <path
        d="M234.714 318.51C234.714 280.153 265.808 249.059 304.165 249.059C342.522 249.059 373.617 280.153 373.617 318.51C373.617 356.867 342.522 387.962 304.165 387.962C265.808 387.962 234.714 356.867 234.714 318.51Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="#FDF9ED"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="3.24"
      />
      <path
        d="M0 83.7962C0 45.4392 31.0945 14.3447 69.4515 14.3447C107.809 14.3447 138.903 45.4392 138.903 83.7962C138.903 122.153 107.809 153.248 69.4515 153.248C31.0945 153.248 0 122.153 0 83.7962Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="#FDF9ED"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="3.24"
      />

      <path
        d="M477.439 390.675L477.439 10.856L539.848 10.856L539.848 390.675L477.439 390.675ZM520.273 222.505L520.273 165.523L732.439 165.523L732.439 222.505L520.273 222.505ZM718.872 390.675L718.872 10.856L781.281 10.856L781.281 390.675L718.872 390.675Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M939.695 393.389L828.444 130.248L894.083 130.248L970.06 323.42L951.066 323.42L1031.37 130.248L1097.59 130.248L976.585 393.389L939.695 393.389ZM862.103 499.213L942.926 332.659L976.585 393.453L928.325 499.278L862.103 499.278L862.103 499.213Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M1232.68 396.102C1209.17 396.102 1187.91 390.158 1168.92 378.206C1149.92 366.254 1134.93 350.102 1123.89 329.622C1112.84 309.207 1107.35 286.336 1107.35 261.011C1107.35 235.685 1112.84 212.298 1123.89 191.818C1134.93 171.402 1149.92 155.121 1168.92 142.975C1187.91 130.894 1209.17 124.821 1232.68 124.821C1252.58 124.821 1270.22 129.085 1285.6 137.548C1300.97 146.076 1313.18 157.706 1322.23 172.565C1331.27 187.424 1335.8 204.222 1335.8 223.022L1335.8 297.901C1335.8 316.701 1331.34 333.563 1322.49 348.358C1313.64 363.217 1301.42 374.847 1285.85 383.375C1270.28 391.838 1252.58 396.102 1232.68 396.102ZM1242.44 339.701C1257.3 339.701 1270.22 336.341 1281.2 329.687C1292.25 322.968 1300.84 313.665 1306.98 301.712C1313.12 289.76 1316.22 276.064 1316.22 260.494C1316.22 244.924 1313.12 231.163 1306.98 219.275C1300.84 207.323 1292.25 198.02 1281.2 191.365C1270.16 184.646 1257.23 181.351 1242.44 181.351C1227.64 181.351 1215.24 184.711 1204.19 191.365C1193.14 198.084 1184.49 207.388 1178.16 219.275C1171.82 231.227 1168.66 244.988 1168.66 260.494C1168.66 275.999 1171.82 289.76 1178.16 301.712C1184.49 313.665 1193.14 322.968 1204.19 329.687C1215.24 336.406 1227.97 339.701 1242.44 339.701ZM1372.1 390.675L1312.41 390.675L1312.41 320.707L1322.74 257.199L1312.41 194.273L1312.41 0.00213249L1372.1 0.00213249L1372.1 390.675Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M1438.32 390.675L1438.32 130.248L1498.02 130.248L1498.02 390.675L1438.32 390.675ZM1498.02 245.828L1477.41 235.491C1477.41 202.607 1484.71 175.924 1499.38 155.444C1514.04 135.029 1536.2 124.756 1565.86 124.756C1578.91 124.756 1590.6 127.147 1601.13 131.799C1611.6 136.515 1621.35 144.074 1630.46 154.604L1591.38 194.725C1586.66 189.686 1581.43 186.068 1575.61 183.871C1569.8 181.674 1563.14 180.641 1555.52 180.641C1538.85 180.641 1525.16 185.874 1514.3 196.405C1503.45 206.871 1498.02 223.345 1498.02 245.764L1498.02 245.828Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M1760.58 396.102C1737.06 396.102 1715.81 390.158 1696.81 378.206C1677.82 366.254 1662.83 350.102 1651.78 329.622C1640.74 309.207 1635.24 286.336 1635.24 261.011C1635.24 235.685 1640.74 212.298 1651.78 191.818C1662.83 171.402 1677.82 155.121 1696.81 142.975C1715.81 130.894 1737.06 124.821 1760.58 124.821C1780.48 124.821 1798.12 129.085 1813.49 137.548C1828.87 146.076 1841.08 157.706 1850.12 172.565C1859.17 187.424 1863.69 204.222 1863.69 223.022L1863.69 297.901C1863.69 316.701 1859.23 333.563 1850.38 348.358C1841.53 363.217 1829.32 374.847 1813.75 383.375C1798.18 391.838 1780.48 396.102 1760.58 396.102ZM1770.34 339.701C1792.37 339.701 1810.2 332.271 1823.76 317.476C1837.33 302.682 1844.12 283.687 1844.12 260.494C1844.12 244.924 1841.01 231.163 1834.88 219.275C1828.74 207.323 1820.15 198.02 1809.1 191.365C1798.05 184.646 1785.13 181.351 1770.34 181.351C1755.54 181.351 1743.14 184.711 1732.09 191.365C1721.04 198.084 1712.38 207.388 1706.05 219.275C1699.72 231.227 1696.56 244.988 1696.56 260.494C1696.56 275.999 1699.72 289.76 1706.05 301.712C1712.38 313.665 1721.04 322.968 1732.09 329.687C1743.14 336.406 1755.86 339.701 1770.34 339.701ZM1840.3 390.675L1840.3 320.707L1850.64 257.199L1840.3 194.273L1840.3 130.248L1900 130.248L1900 390.675L1840.3 390.675Z"
        fill="#FDF9ED"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
    </SvgIcon>
  );
};
