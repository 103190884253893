"use client";

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    unit: "rem",
  },
  palette: {
    background: {
      default: "#FDF9ED",
    },
    primary: {
      main: "#6A488E",
      light: "#ffc278",
      dark: "#ffc278",
      contrastText: "#FDF9ED",
    },
    secondary: {
      main: "#FDF9ED",
      light: "#FDF9ED",
      dark: "#FDF9ED",
      contrastText: "#333333",
    },
    error: {
      main: "#ff4d4d",
      light: "#ff5e5e",
      dark: "#ff5e5e",
      contrastText: "#FDF9ED",
    },
    grey: {
      900: "#333333",
      800: "#474747",
      700: "#5b5b5b",
      600: "#707070",
      500: "#848484",
      400: "#999999",
      300: "#adadad",
      200: "#c1c1c1",
      100: "#d6d6d6",
    },
    text: {
      primary: "#FDF9ED",
      secondary: "#FDF9ED",
      disabled: "#848484",
    },
    action: {
      hover: "rgba(168, 106, 255, 0.1)",
    },
  },
  typography: {
    fontFamily: [
      "Space Grotesk",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontWeightBold: 500,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
  },
  spacing: [0, 8, 16, 32, 64, 128],
  shape: {
    borderRadius: 15,
  },
  transitions: {
    duration: {
      shortest: 50,
      shorter: 75,
      short: 150,
      // most basic recommended timing
      standard: 200,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          width: "min(20rem, calc(100vw - 7rem))",
          maxWidth: "min(calc(100% - 2rem), calc(100vw - 7rem))",
          color: "#FDF9ED",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginLeft: "0rem",
          paddingLeft: "0rem",
          maxWidth: "min(calc(100% - 2rem), calc(100vw - 2rem))",
          color: "#FDF9ED",

          border: "none"
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: "1.5rem",
          fontSize: "calc(1rem + (2 * ((100vw - 375px) / 1225)))",

          color: "#6A488E",
          background: "#FDF9ED",
          borderTop: "0.2rem solid #6A488E",
          borderLeft: "0.2rem solid #6A488E",
          borderBottom: "0.2rem solid #6A488E",
          borderRight: "0.2rem solid #6A488E",
          borderRadius: "2rem",
          transform: "scale(0.95)",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
          transition: "0.15s all ease-in-out",
          textTransform: "none",

          "&:hover": {
            transform: "scale(1)",
            color: "#6A488E",
            background: "#FDF9ED",
            outlined: {
              paddingLeft: "0.75rem",
              paddingRight: "0.75rem",
              text: {
                paddingLeft: "0.75rem",
                paddingRight: "0.75rem",
              },
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#6A488E",
          border: "0.2rem solid #6A488E",
          borderRadius: "2rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          color: "#FDF9ED",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: "1rem",
          background: "#6A488E",
          border: "none",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#FDF9ED",
          textAlign: "center",
          justifyContent: "center",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          color: "#6A488E",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "inherit",
          border: "none",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          overflowX: "hidden" /* Hide horizontal scrollbar */,
          textAlign: "center",
        },
      },
    },
  },
});

export default theme;
