import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/src/components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarLanding"] */ "/vercel/path0/src/components/NavbarLanding/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/themes/themes.tsx");
