"use client";

import Link from "next/link";
import { useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import { usePlausible } from "next-plausible";

import styles from "@/styles/header.module.scss";

import HydraTextIcon from "@/components/MuiIcons/HydraTextIcon";

export const NavbarLanding: React.FC = (props) => {
  useEffect(() => {
    document.body.style.overflow = "unset";
  });

  const t = useTranslations("Common"); // default namespace (optional)
  const plausible = usePlausible();

  const [active, setActive] = useState(false);
  const handleClick = () => {
    setActive(!active);
    !active
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  };

  return (
    <nav className={[styles.light, styles.nav].join(" ")}>
      <div
        className={[styles.hamburgerContainer, "invisible"].join(" ")}
      >
        <div
          className={[
            styles.mobileHamburger,
            active ? styles.hamburgerActive : "",
          ].join(" ")}
        ></div>
      </div>

      <ul
        className={[
          "invisible", styles.section,
          styles.relativeContainer,
          styles.menuLinks,
          active ? styles.menuActive : "",
        ].join(" ")}
      >
        <li>
          <div
            className={[styles.menuLinkPadding].join(" ")}
          >
            {t("header-aboutLink")}
          </div>
        </li>
        <li>
          <div
            className={[styles.menuLinkPadding].join(" ")}
          >
            {t("header-contactLink")}
          </div>
        </li>
        <li>
          <div
            className={[styles.menuLinkPadding].join(" ")}
          >
            {t("header-contactLink")}
          </div>
        </li>
        <li>
          <div
            className={[styles.menuLinkPadding].join(" ")}
          >
            {t("header-contactLink")}
          </div>
        </li>
      </ul>


      <Link
        href={`/`}
        onClick={() => {
          document.body.style.overflow = "unset";
          setActive(false);
        }}
        className={["m-0 h-8 flex flex-col gap-1"].join(" ")}
        aria-label={"Link to the Hydra homepage as the logo"}
      >
        <HydraTextIcon style={{ height: "3rem", width: "auto", fill: "#55555"}} />
      </Link>

      <div
        onClick={() => {
          handleClick();
        }}
        className={styles.hamburgerContainer}
      >
        <div
          className={[
            styles.mobileHamburger,
            active ? styles.hamburgerActive : "",
          ].join(" ")}
        ></div>
      </div>

      <ul
        className={[
          styles.section,
          styles.relativeContainer,
          styles.menuLinks,
          active ? styles.menuActive : "",
        ].join(" ")}
      >
        <li>
          <Link
            href={`/about`}
            onClick={() => {
              setActive(false);
            }}
            aria-label={"bounty-list-view-link"}
            className={[styles.menuLinkPadding].join(" ")}
          >
            {t("header-aboutLink")}
          </Link>
        </li>
        <li>
          <Link
            href={`/#contact`}
            onClick={() => {
              setActive(false);
            }}
            aria-label={"community-view-link"}
            className={[styles.menuLinkPadding].join(" ")}
          >
            {t("header-contactLink")}
          </Link>
        </li>
        <li>
          <Link
            href={`/blog`}
            onClick={() => {
              setActive(false);
            }}
            aria-label={"blog-link"}
            className={[styles.menuLinkPadding].join(" ")}
          >
            {t("header-blogLink")}
          </Link>
        </li>
        <li>
          <Link
            href={`/jobs`}
            onClick={() => {
              setActive(false);
            }}
            aria-label={"jobs-link"}
            className={[styles.menuLinkPadding].join(" ")}
          >
            {t("header-jobsLink")}
          </Link>
        </li>
      </ul>
    </nav>
  );
};
